import Choices from '../../../node_modules/choices.js/public/assets/scripts/choices.js';
import { CUSTOM_EVENTS_MAP } from './choices.constants.js';
_ngInjectExport.$inject = ["$attrs", "$element", "$parse", "$scope", "choiceDefaultConfig", "$transclude"];
export default /* @ngInject */function _ngInjectExport($attrs, $element, $parse, $scope, choiceDefaultConfig, $transclude) {
  const ctrl = this;
  let options,
    choicesObj,
    choisesOldVal,
    emptyOption,
    isInitialized = false;
  ctrl.$onInit = () => {
    validationOptionParams('label');
    validationOptionParams('value');
    ctrl.choiceItems = [];
    options = Object.assign({}, choiceDefaultConfig, ctrl.options || {});
    if ($attrs.callbackOnInit != null) {
      options.callbackOnInit = () => ctrl.callbackOnInit({
        choices: choicesObj
      });
    }
    if ($attrs.callbackOnCreateTemplates != null) {
      options.callbackOnCreateTemplates = template => ctrl.callbackOnCreateTemplates({
        template
      });
    }
    if ($attrs.sorter != null) {
      options.sorter = (a, b) => ctrl.sorter({
        a,
        b
      });
    }
    if ($attrs.addItemText != null) {
      options.addItemText = value => ctrl.addItemText({
        value
      });
    }
    if ($attrs.maxItemText != null) {
      options.maxItemText = maxItemCount => ctrl.maxItemText({
        maxItemCount
      });
    }
    if ($attrs.valueComparer != null) {
      options.valueComparer = (value1, value2) => ctrl.valueComparer({
        value1,
        value2
      });
    }
    if ($transclude.isSlotFilled('ngChoicesItemTemplate') || $transclude.isSlotFilled('ngChoicesChoiceTemplate')) {
      options.allowHTML = true;
      const templateConfig = {};
      if ($transclude.isSlotFilled('ngChoicesItemTemplate')) {
        templateConfig.item = function () {
          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }
          const base = Choices.defaults.templates.item(...args);
          return createTemplate('ngChoicesItemTemplate', base, args);
        };
      }
      if ($transclude.isSlotFilled('ngChoicesChoiceTemplate')) {
        templateConfig.choice = function () {
          for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
            args[_key2] = arguments[_key2];
          }
          const base = Choices.defaults.templates.choice(...args);
          return createTemplate('ngChoicesItemTemplate', base, args);
        };
      }
      options.callbackOnCreateTemplates = () => {
        return templateConfig;
      };
    }
  };
  ctrl.$postLink = () => {
    const select = $element[0].querySelector('select');
    const modelValueSetter = $parse($attrs.ngModel).assign;
    select.addEventListener('choice', event => {
      if (event.detail.choice.disabled) {
        return;
      }
      modelValueSetter($scope.$parent, event.detail.choice.customProperties || event.detail.choice.value);
      $scope.$apply();
    });
    for (const eventKey in CUSTOM_EVENTS_MAP) {
      if ($attrs[eventKey] != null) {
        select.addEventListener(CUSTOM_EVENTS_MAP[eventKey], function () {
          for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
            args[_key3] = arguments[_key3];
          }
          ctrl[eventKey]({
            ...args
          });
        });
      }
    }
    choicesObj = new Choices(select, options);
    $scope.$watch(() => ctrl.ngModel.$modelValue, (newVal, oldValue) => {
      if (ctrl.ngModel.$modelValue == null) {
        emptyOption = emptyOption || {
          label: 'Не выбрано',
          value: '',
          selected: true
        };
        choicesObj.setChoices([emptyOption, ...ctrl.choiceItems], 'value', 'label', true);
        return;
      }
      const itemSelected = ctrl.choiceItems.find(x => x.customProperties === ctrl.ngModel.$modelValue);
      if (itemSelected != null) {
        choicesObj.setChoiceByValue(itemSelected.value);
      }
    });
    if (ctrl.choices != null) {
      ctrl.setChoices(ctrl.choices);
    }
    isInitialized = true;
  };
  ctrl.$doCheck = () => {
    if (isInitialized && angular.equals(choisesOldVal, ctrl.choices) === false) {
      ctrl.setChoices(ctrl.choices);
      choisesOldVal = angular.copy(ctrl.choices);
    }
  };
  ctrl.setChoices = data => {
    ctrl.choiceItems.length = 0;
    let valueTemp, customPropertiesTemp, locals;
    for (const choicesDataItem of data) {
      locals = {
        choice: {
          customProperties: choicesDataItem
        }
      };
      valueTemp = ctrl.valueKey != null ? choicesDataItem[ctrl.valueKey] : ctrl.valueFn(locals);
      customPropertiesTemp = ctrl.customPropertiesKey != null ? choicesDataItem[ctrl.customPropertiesKey] : ctrl.customPropertiesFn(locals);
      ctrl.choiceItems.push({
        value: valueTemp,
        label: ctrl.labelKey != null ? choicesDataItem[ctrl.labelKey] : ctrl.labelFn(locals),
        disabled: ctrl.disabledKey != null ? choicesDataItem[ctrl.disabledKey] : ctrl.disabledFn(locals),
        customProperties: customPropertiesTemp,
        selected: angular.equals(ctrl.ngModel.$modelValue, customPropertiesTemp || valueTemp)
      });
    }
    choicesObj.setChoices(ctrl.choiceItems, 'value', 'label', true);
  };
  const validationPostfix = ['Key', 'Fn'];
  const validationOptionParams = baseProp => {
    if (validationPostfix.every(x => $attrs[baseProp + x] == null)) {
      throw new Error('Missing one of the options: ' + validationPostfix.map(x => baseProp + x).join(' or '));
    }
  };
  const createTemplate = (slotName, base, _ref) => {
    let [config, choice, ...specificArgs] = _ref;
    const scopeChild = $scope.$parent.$new();
    scopeChild.config = config;
    scopeChild.choice = choice;
    const el = $transclude(scopeChild, (clone, scope) => {
      return clone;
    }, null, slotName);
    base.textContent = '';
    base.appendChild(el[0]);
    return base;
  };
}